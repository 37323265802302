@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/bootstrap";
@import "variables";
@import "lightslider.css";
@import "animate.css";

@import "~flatpickr/dist/themes/airbnb.css";
@import "~hopscotch/dist/css/hopscotch.css";
@import '~react-day-picker/lib/style.css';
@import '~slick-carousel/slick/slick.css';

@import '~@fortawesome/fontawesome-pro/css/all.css';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome.scss';

@import url($font-header-url);
@import url($font-family-url);

::selection {
  background: $brown-light;
  color: $gray-100; }

::-moz-selection {
  background: $brown-light;
  color: $gray-100; }

// @media screen and (min-width: 1600px)
//   html
//     font-size: 20px

body {
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  color: $brown; }

@media (max-width: 767.98px) {
  body {
    padding-top: 0; } }

a, a span {
  transition: all .3s ease-out; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: $brown;
  line-height: 1.1;
  font-family: 'Denk One', sans-serif;
  font-weight: 300; }

.text-primary {
  color: $brand-primary !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #37302c!important; }

.text-secondary {
  color: $brand-secondary!important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: $pink-light!important; }

.text-success {
  color: $brand-success; }

a.text-success:focus, a.text-success:hover {
  color: #299f64!important; }

.container {
  @media(min-width: 1400px) {
    max-width: 1260px; } }

.form-group label {
  font-size: .8em; }

.form-group input.form-control, .form-group input.form-control:focus {
  background-color: $gray-semilight;
  -webkit-appearance: none;
  color: $pink;
  border: 1px solid $pink; }

input.form-control.datepicker {
  &::placeholder {
    color: $pink; } }

.form-group .l--wrap-calendar:after, .form-group .l--wrap-select:after {
  font-family: "Font Awesome 5 Pro";
  font-size: 20px;
  line-height: 38px;
  padding: 0 10px;
  display: block;
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  bottom: 0; }

// @media screen and (min-width: 1600px)
//   .form-group .l--wrap-calendar:after,
//   .form-group .l--wrap-select:after
//     line-height: 46px

.form-group .l--wrap-calendar {
  position: relative;
  background-color: $gray-semilight; }

.form-group .l--wrap-calendar:after {
  background: transparent;
  color: $pink;
  content: "\f073"; }

.form-group .l--wrap-calendar .form-control,
.form-group .l--wrap-calendar .form-control:focus {
  background: transparent;
  position: relative;
  z-index: 1;
  padding-right: 40px; }

.form-group .l--wrap-select {
  position: relative;
  background-color: $gray-semilight; }

.form-group .l--wrap-select:after {
  background: transparent;
  color: $brown-light;
  content: "\f107"; }

.form-group .l--wrap-select select.form-control,
.form-group .l--wrap-select select.form-control:focus {
  background: transparent;
  color: $brown-light;
  border: 1px solid $gray-semilight;
  display: inline-block;
  position: relative;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none; }

.form-group .l--wrap-select select.form-control:not([size]):not([multiple]) {
  height: auto;
  padding: .5rem .75rem; }

.form-group .l--wrap-select select.form-control:active,
.form-group .l--wrap-select select.form-control:focus {
  background-color: $gray-semilight!important; }

.l--box-newsletter .form-control,
.l--box-newsletter .form-control:focus,
.l--box-newsletter .l--wrap-calendar,
.l--box-newsletter .l--wrap-select {
  background-color: $white!important;
  color: $brown!important;
  border-color: $brown!important; }

.l--box-newsletter select.form-control,
.l--box-newsletter select.form-control:focus {
  border-color: $brown!important; }

.l--box-newsletter a,
.l--box-newsletter a:hover {
  color: $white; }

.btn-secondary {
  color: $white!important; }

.btn-primary {
  color: $white;
  background-color: $pink;
  border-color: $pink-dark; }

.btn-primary:hover {
  color: $white;
  background-color: $pink-dark;
  border-color: $pink-dark; }

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(84, 72, 66, .5); }

.btn-primary.disabled, .btn-primary:disabled {
  color: $white;
  background-color: #c472a8;
  border-color: $pink-dark; }

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #c472a8;
  border-color: #9D437E; }


.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #37302c;
  border-color: #302926; }


.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(84, 72, 66, .5); }


.btn-tertiary {
  color: $white;
  background-color: $green-dark;
  border-color: $green-dark-medium; }

.btn-tertiary:hover {
  color: $white;
  background-color: $green-dark;
  border-color: $green-lighter; }

.btn-tertiary.focus, .btn-tertiary:focus {
  box-shadow: 0 0 0 .2rem $green-dark-medium-trasp; }

.btn-tertiary.disabled, .btn-tertiary:disabled {
  color: $white;
  background-color: $green;
  border-color: $green-dark-medium; }

.btn-tertiary:not(:disabled):not(.disabled).active,
.btn-tertiary:not(:disabled):not(.disabled):active,
.show>.btn-tertiary.dropdown-toggle {
  color: $white;
  background-color: $green-dark;
  border-color: $green-lighter; }

.btn-tertiary:not(:disabled):not(.disabled).active:focus,
.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.show>.btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem $green-dark-medium-trasp; }

.btn-tertiary {
  color: $white!important; }

.g--logo,
.g--logo img {
  transition: all .3s ease-out;
  width: 168px;
  max-width: 100%; }

@media (max-width: 767.98px) {
  .g--logo, .g--logo img {
    max-width: 80px; } }

.l--navbar, .navbar {
  padding: 0; }

@media (max-width: 767.98px) {
  .l--navbar {
    z-index: 1080;
    height: 0;
    background: transparent;
    top: 0;
    left: 0;
    right: 0; }

  .l--navbar.l--fromLeft #u--collapse-navbar {
    display: block!important;
    height: auto!important;
    margin-left: -322px;
    transition: all .3s ease-out; }

  .l--navbar.l--fromLeft #u--collapse-navbar.collapse.in {
    margin-left: 0; } }

.l--navbar_esteso {
  padding: 0; }

@media (min-width: 768px) {
  .l--navbar_esteso .nav-vert .dropdown-menu {
    top: 0;
    left: 98%; } }

.l--navbar_esteso .nav-vert .nav-item {
  margin: 2px 0!important; }

.l--navbar_esteso .nav-item {
  margin: 0 -3px!important; }

.l--navbar_esteso .nav-link {
  display: block;
  background: transparent;
  color: $white;
  padding: 0!important;
  border-radius: 0; }

.l--navbar_esteso .nav-link:hover {
  background: #454040;
  color: $white; }

.l--navbar_esteso .nav-link span {
  display: block;
  background: transparent;
  padding: .5rem 1rem;
  border-width: 1px 0;
  border-style: solid;
  border-color: transparent;
  text-transform: uppercase; }

.l--navbar_esteso .nav-link span:hover {
  background: $gray-600; }

.l--navbar_esteso .nav-link.active span {
  background: $gray-600;
  color: $white; }

.l--navbar_esteso .dropdown-toggle {
  position: relative; }

.l--navbar_esteso .dropdown-toggle:after {
  position: absolute;
  top: 40%;
  right: 5px; }

.l--navbar_esteso .dropdown-menu {
  margin-top: 0; }

@media (max-width: 991.98px) {
  .l--navbar_esteso .dropdown-menu {
    position: relative;
    float: none!important; } }

.l--navbar_esteso .dropdown-item {
  color: $white; }

.l--navbar_esteso .dropdown-item:hover {
  background-color: $gray-500; }

.l--navbar_esteso #u--collapse-lingue,
.l--navbar_esteso #u--collapse-navbar {
  background: transparent; }

@media (max-width: 991.98px) {
  .l--navbar_esteso {
    position: fixed;
    z-index: 1080;
    height: 0;
    background: transparent;
    top: 0;
    left: 0;
    right: 0; }

  .l--navbar_esteso #u--collapse-lingue,
  .l--navbar_esteso #u--collapse-navbar {
    width: 320px;
    padding: 8px; }

  .l--navbar_esteso #u--collapse-lingue ul {
    margin: 0;
    padding: 0; }

  .l--navbar_esteso #u--collapse-lingue ul li {
    list-style: none; }

  .l--navbar_esteso.l--fromLeft #u--collapse-navbar {
    display: block!important;
    height: auto!important;
    margin-left: -322px;
    transition: all .3s ease-out; }

  .l--navbar_esteso.l--fromLeft #u--collapse-navbar.collapse.in {
    margin-left: 0; } }

.tag {
  position: relative;
  top: -8px;
  margin-left: 5px;
  display: inline-block;
  padding: .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }

.tag-primary {
  background-color: $brown-light;
  color: $white; }

.g--dp-call-cnt {
  width: 100%;
  margin: 0 auto;
  line-height: 1;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-content: center; }

.g--dp-call-cnt .g--dp-btn {
  width: 30%;
  margin: 0 1px;
  padding: 0; }

.g--dp-call-cnt .g--dp-call {
  width: 35%;
  //border: 1px solid #ccc
  padding: 0;
  margin: 0 1px;
  position: relative; }

.g--dp-call-cnt .g--input-call {
  opacity: 0!important;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2.2rem 0; }

.g--dp-call-cnt .g--dp-label {
  font-size: .75em;
  text-align: center;
  width: 100%;
  background: #fafafa;
  padding: 6px; }

.g--dp-call-cnt .g--dp-d {
  font-size: 2.5em;
  line-height: 1;
  text-align: center;
  width: 100%;
  background: $white;
  color: $brown-light;
  padding: 0 8px 8px; }

.g--dp-call-cnt .g--dp-m {
  width: 40%;
  float: left; }

.g--dp-call-cnt .g--dp-m,
.g--dp-call-cnt .g--dp-y {
  font-size: .75em;
  text-align: center;
  background: $brown-light;
  color: $white;
  padding: 6px; }

.g--dp-call-cnt .g--dp-y {
  width: 60%;
  float: right; }

.l--fixed-footer, .l--fixed-header, .l--fixed-leftbar, .l--fixed-rightbar {
  background: transparent; }

.l--fixed .l--fixed-footer, .l--fixed .l--fixed-header, .l--fixed .l--fixed-leftbar, .l--fixed .l--fixed-rightbar {
  position: fixed;
  z-index: 1030; }

.l--fixed .l--fixed-leftbar {
  width: 0;
  top: 0;
  bottom: 0; }

.l--fixed .l--fixed-header {
  min-height: 0;
  left: 0;
  top: 0;
  right: 0; }

.l--fixed .l--fixed-rightbar {
  z-index: 1030;
  width: 0;
  right: 0;
  bottom: 0;
  top: 0; }

.l--fixed .l--fixed-footer {
  z-index: 1030;
  height: 0;
  right: 0;
  left: 0;
  bottom: 0; }

.l--fixed .l--main-wrap {
  margin: 0; }

.l--fixed .l--main-wrap>.l--inner {
  padding: 20px; }

.l--stretched-cnt {
  margin-left: -20px;
  margin-right: -20px; }

.l--max-width {
  width: calc(100% - 100px);
  margin: 0 auto; }

.display-1 {
  font-size: 6rem; }

.display-1, .display-2, .display-3, .display-4 {
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem; }

.display-3 {
  font-size: 2rem; }

.display-4 {
  font-size: 1.3rem; }

.l--h-100 {
  height: 100%; }

.l--h-75 {
  height: 75%; }

.l--h-50 {
  height: 50%; }

@media (max-width: 767.98px) {
  .l--h-50 {
    height: 100%; } }

.l--h-25 {
  height: 25%; }

@media (max-width: 767.98px) {
  .l--h-25, .l--sm-h-100 {
    height: 100%; } }

.l--w-100 {
  width: 100%; }

.l--w-75 {
  width: 75%; }

.l--w-50 {
  width: 50%; }

.l--w-25 {
  width: 25%; }

.js--to-top {
  position: fixed;
  z-index: 2000;
  bottom: 10px;
  right: 10px; }

.lâcontainer-limit {
  position: relative;
  max-width: 1920px !important;
  margin: auto;
  box-shadow: 0 0 12px 1px rgba(51, 51, 51, .7); }

.g--bkg-dark-01 {
  background: $gray-500; }

.g--bkg-dark-02 {
  background: $pink; }

.g--bkg-dark-03 {
  background: $brown; }

.g--bkg-dark-04 {
  background: rgba(157, 67, 126, .8); }

.g--bkg-dark-05 {
  background: #EF8C06; }

.g--bkg-light-01 {
  background: #EBEBEB; }

.g--bkg-light-02 {
  background: $green-dark; }

.g--bkg-light-03 {
  background: $white; }

.g--bkg-light-04 {
  background: $white; }

.g--bkg-light-05 {
  background: $green-darker; }

.g--bkg-light-06 {
  background: #6BA280; }

.g--bkg-social-fb {
  background: #295187; }

.g--bkg-social-gp {
  background: #d6382e; }

.g--bkg-social-tw {
  background: #14c4f4; }

.g--c-social-fb {
  color: #295187; }

.g--c-social-gp {
  color: #d6382e; }

.g--c-social-tw {
  color: #14c4f4; }

.text-tertiary {
  color: $gray; }

.text-quaternary {
  color: $white; }

.g--dp-call-cnt .g--dp-d,
.g--dp-call-cnt .g--dp-label,
.g--dp-call-cnt .g--dp-m,
.g--dp-call-cnt .g--dp-y,
.text-no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.text-small {
  font-size: .85rem; }

.text-x-small {
  font-size: .75rem; }

@media (min-width: 576px) {
  .l--text-column-2 {
    column-count: 2;
    column-gap: 20px; } }

.g--text-shadow {
  text-shadow: 1px 1px 2px #858181; }

.g--box-shadow {
  box-shadow: 0 2px 10px 0 rgba(0, 3, 10, .7); }

ul.awesome {
  list-style: none;
  padding: 0; }

ul.awesome li {
  padding-left: 1.3em; }

ul.awesome li:before {
  content: "\f00c ";
  font-family: "Font Awesome\ 5 Pro"!important;
  color: $gray-600;
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em; }

.text-over-slider {
  color: $white;
  background-color: transparent;
  text-shadow: 2px 2px 4px #000;
  padding: 0; }

.slick-list,
.slick-track {
  height: 100%; }

.slick-dots {
  bottom: -40px; }

.slick-dotted.slick-slider {
  margin-bottom: 0; }

.slick-next, .slick-prev {
  top: 50%!important; }

.slick-prev:before {
  content: "\f104 "!important; }

.slick-next:before, .slick-prev:before {
  font-family: Font Awesome\ 5 Pro!important;
  font-weight: 300; }

.slick-next:before {
  content: "\f105 "!important; }

.slick-dots li button:before {
  color: $brown-light;
  content: "\f192 "!important;
  font-family: Font Awesome\ 5 Pro!important;
  opacity: 1; }

.slick-dots li.slick-active button:before {
  color: $brand-fifth!important;
  opacity: 1; }

.g--b-c-01 {
  border-color: #EBEBEB!important; }

.g--b-c-02 {
  border-color: #71AD88!important; }

.g--b-c-03 {
  border-color: #f0ad4e!important; }

.g--b-c-04 {
  border-color: #d9534f!important; }

.g--b-c-light {
  border-color: $white!important; }

.l--rotate-90 {
  transform: scale(1) rotate(90deg) translateX(0) translateY(0) skewX(0deg) skewY(0deg); }

.l--align-middle-vertical {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center; }

@media (min-width: 576px) {
  .js--hideme, .js--revealMe {
    opacity: 0; } }

.js--showme {
  display: none; }

.l--position-relative {
  position: relative; }

.l--position-absolute {
  position: absolute; }

.l--position-fixed {
  position: fixed; }

.l--position-absolute, .l--position-fixed, .position-absolute {
  z-index: 100;
  left: 0;
  right: 0; }

.l--position-absolute.l--top, .l--position-fixed.l--top, .position-absolute.l--top {
  top: 0; }

.l--position-absolute.l--bottom, .l--position-fixed.l--bottom, .position-absolute.l--bottom {
  bottom: 0; }

.l--position-absolute.l--right, .l--position-fixed.l--right, .position-absolute.l--right {
  left: auto; }

.l--position-absolute.l--left, .l--position-fixed.l--left, .position-absolute.l--left {
  right: auto; }

.l--gallery-grid li {
  list-style: none!important; }

.l--gallery-grid .l--p-gall {
  padding: 5px; }

.l--gallery-grid div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%; }

.l--gallery-grid .l--height-1 {
  height: 225px; }

@media (max-width: 767.98px) {
  .l--gallery-grid .l--height-1 {
    height: 112.5px; } }

.l--gallery-grid .l--height-2 {
  height: 225px; }

@media (max-width: 767.98px) {
  .l--gallery-grid .l--height-2 {
    height: 112.5px; } }

.l--gallery-grid .l--height-3 {
  height: 450px; }

@media (max-width: 767.98px) {
  .l--gallery-grid .l--height-3 {
    height: 225px; } }

.l--gallery-grid .w-sm-100 {
  width: 50%; }

@media (max-width: 767.98px) {
  .l--gallery-grid .w-sm-100 {
    width: 100%!important; } }

.l--gallery-grid .h-sm-100 {
  height: 50%!important; }

@media (max-width: 767.98px) {
  .l--gallery-grid .h-sm-100 {
    height: 100%!important; } }

.l--arrow_box {
  position: relative;
  background: $brand-fifth; }

.l--arrow_box:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: $brand-fifth;
  border-width: 50px;
  margin-top: -50px; }

.l--arrow_box.top:after {
  bottom: 100%;
  left: 50%; }

.l--arrow_box.right:after {
  left: 100%; }

.l--arrow_box.bottom:after {
  top: 100%;
  left: 50%; }

.l--arrow_box.right:after {
  right: 100%;
  top: 50%; }

.l-video-bg-wrap {
  display: block;
  overflow: hidden;
  height: 100%;
  z-index: 0; }

.l-video-bg, .l-video-bg-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.l-video-bg {
  min-width: 100%;
  min-height: 100%;
  height: auto;
  object-fit: cover; }

#map-canvas {
  height: 400px; }

.l--vh-100 {
  height: 100vh; }

@media (min-width: 576px) {
  .l--vh-100-sm {
    height: 100vh; } }

.l--vh-100-md {
  height: 50vh; }

@media (min-width: 768px) {
  .l--vh-100-md {
    height: 100vh; } }

@media (min-width: 992px) {
  .l--vh-100-lg {
    height: 100vh; } }

@media (min-width: 1200px) {
  .l--vh-100-xl {
    height: 100vh; } }

.l--vh-50 {
  height: 50vh; }

@media (min-width: 576px) {
  .l--vh-50-sm {
    height: 50vh; } }

.l--vh-50-md {
  height: 50vh; }

@media (min-width: 768px) {
  .l--vh-50-md {
    height: 50vh; } }

@media (min-width: 992px) {
  .l--vh-50-lg {
    height: 50vh; } }

@media (min-width: 1200px) {
  .l--vh-50-xl {
    height: 50vh; } }

.l--vh-75 {
  height: 75vh; }

@media (min-width: 576px) {
  .l--vh-75-sm {
    height: 75vh; } }

.l--vh-75-md {
  height: 50vh; }

@media (min-width: 768px) {
  .l--vh-75-md {
    height: 75vh; } }

@media (min-width: 992px) {
  .l--vh-75-lg {
    height: 75vh; } }

@media (min-width: 1200px) {
  .l--vh-75-xl {
    height: 75vh; } }

.g--bkg-burger {
  background: transparent; }

#btn-menu, #btn-menu-close, #menu-wrapper, .btn-menu, .btn-menu-close {
  overflow: hidden;
  max-width: 100%;
  cursor: pointer; }

.btn-menu-cat {
  color: $pink;
  background-color: #EBEBEB;
  border-color: $pink;
  border-radius: 25px; }

.btn-menu-cat:hover {
  color: #fff !important;
  background-color: $pink;
  border-color: $pink; }

.slide-left {
  left: -110%; }

.slide-left.opened {
  left: 0; }

.slide-right {
  right: -110%; }

.slide-right.opened {
  right: 0; }

#menuContainer ul, .menuContainer ul {
  list-style: none; }

#menuContainer, .menuContainer {
  display: block;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 9999999;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all .3s ease-out; }

#menuContainer.opened, .menuContainer.opened {
  visibility: visible;
  position: fixed;
  opacity: 1; }

#menuContainer .g--menu, .menuContainer .g--menu {
  width: 100%;
  height: 100%;
  float: right;
  background: #887763;
  background: linear-gradient(180deg, #887763, rgba(0, 0, 0, .8)); }

.subMenu {
  display: none;
  text-align: left; }

.subMenu a, .subMenu ul {
  padding-left: 0; }

.subMenu.open {
  display: block; }

.subMenu.open a, .subMenu.open span {
  padding-left: 15px; }

.menu-toggle {
  position: relative;
  padding: 10px 0!important; }

.menu-toggle:after {
  transform-origin: 50% 50%;
  transition: all .3s ease-out; }

.menu-toggle:before {
  transform: rotate(-90deg);
  opacity: 1;
  z-index: 2; }

.menu-toggle.active:before {
  transform: rotate(0deg);
  background: $white!important; }

.menu-toggle.active:after {
  transform: rotate(180deg);
  background: $white!important;
  opacity: 0; }

#menu-esteso {
  position: relative;
  overflow: hidden; }

#btn-menu #hamburger-menu, #btn-menu-close #closeMenu, #menu-wrapper #hamburger-menu,
.btn-menu-close .closeMenu, .btn-menu .hamburger-menu {
  position: relative;
  width: 25px;
  height: 20px;
  margin: 15px; }

#btn-menu #hamburger-menu span, #btn-menu-close #closeMenu span, #menu-wrapper #hamburger-menu span,
.btn-menu-close .closeMenu span, .btn-menu .hamburger-menu span {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  color: #000;
  background-color: $white;
  position: absolute;
  transform: rotate(0deg);
  transition: .4s ease-in-out; }

#btn-menu #hamburger-menu span:first-child, #menu-wrapper #hamburger-menu span:first-child,
.btn-menu .hamburger-menu span:first-child {
  top: 0; }

#btn-menu #hamburger-menu span:nth-child(2), #menu-wrapper #hamburger-menu span:nth-child(2),
.btn-menu .hamburger-menu span:nth-child(2) {
  top: 9px; }

#btn-menu #hamburger-menu span:nth-child(3), #menu-wrapper #hamburger-menu span:nth-child(3),
.btn-menu .hamburger-menu span:nth-child(3) {
  top: 18px; }

#btn-menu-close #closeMenu span:first-child, #menu-wrapper #hamburger-menu.open span:first-child,
.btn-menu-close .closeMenu span:first-child {
  top: 9px;
  transform: rotate(135deg); }

#btn-menu-close #closeMenu span:nth-child(2), #menu-wrapper #hamburger-menu.open span:nth-child(2),
.btn-menu-close .closeMenu span:nth-child(2) {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  left: -60px; }

#btn-menu-close #closeMenu span:nth-child(3), #menu-wrapper #hamburger-menu.open span:nth-child(3),
.btn-menu-close .closeMenu span:nth-child(3) {
  top: 9px;
  transform: rotate(-135deg); }

#menu-container .menu-list .menu-submenu, #menu .menu-list .menu-submenu,
.menu .menu-list .menu-submenu {
  padding-left: 15px;
  background: #887763; }

#menu .menu-list .menu-submenu li, .menu .menu-list .menu-submenu li {
  border: none;
  font-weight: 400; }

#menu .menu-list .menu-submenu li a, .menu .menu-list .menu-submenu li a {
  text-transform: capitalize; }

#menu .menu-list, .menu .menu-list {
  padding-left: 0;
  display: block;
  background: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  width: 80%;
  margin: 0;
  padding-top: 1rem; }

#menu .menu-list a, .menu .menu-list a {
  color: $brand-fifth;
  display: block;
  padding: 14px;
  text-transform: uppercase; }

#menu .menu-list a:hover, .menu .menu-list a:hover {
  background-color: $gray-200; }

#menu .menu-list a.active, .menu .menu-list a.active {
  background: $gray-200;
  color: $brand-fifth; }

#menu-container .menu-list {
  padding-left: 0;
  display: block;
  visibility: hidden;
  position: absolute;
  min-width: 300px;
  max-width: 450px;
  background: transparent;
  box-shadow: 6px 2px 10px hsla(0, 0%, 39%, .2);
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  left: -110%;
  transition: all .3s ease-out; }

#menu-container .menu-list.active {
  visibility: visible; }

#menu-container .menu-list a {
  color: $brand-fifth;
  display: block;
  padding: 14px; }

#menu-container .menu-list a:hover {
  background-color: $gray-200; }

#menu-container .menu-list a.active {
  background: $gray-200;
  color: $brand-fifth; }

#menu-container .menu-list .menu-login, #menu-container .menu-list li {
  font-size: 16px;
  border-top: 1px solid #b6aa9b;
  list-style: none; }

#menu .menu-list li, .menu .menu-list li {
  font-size: 18px;
  border-top: 1px solid $brand-fifth;
  list-style: none;
  font-weight: 700; }

#menu-container .menu-list li:first-of-type, #menu .menu-list li:first-of-type,
.menu .menu-list li:first-of-type {
  border-top: 0; }

.accordion-content, .accordion-toggle {
  cursor: pointer;
  font-size: 16px;
  position: relative; }

.accordion-content {
  display: none; }

.accordion-toggle a:after, .accordion-toggle a:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 15px;
  height: 2px;
  margin-top: -1px;
  background-color: $white;
  transform-origin: 50% 50%;
  transition: all .3s ease-out; }

.accordion-toggle a:before {
  transform: rotate(-90deg);
  opacity: 1;
  z-index: 2; }

.accordion-toggle.active-tab {
  background: $gray-200;
  transition: all .3s ease; }

.accordion-toggle a.active:before {
  transform: rotate(0deg);
  background: $white!important; }

.accordion-toggle a.active:after {
  transform: rotate(180deg);
  background: $white!important;
  opacity: 0; }

.gâopacity {
  opacity: .7;
  filter: alpha(opacity=70); }

.gâopacity:hover {
  opacity: 1; }

.l--parallax-100 {
  height: 100vh; }

.l--parallax-50, .l--parallax-100 {
  background-size: cover!important;
  background-attachment: fixed!important;
  width: 100%;
  background-repeat: no-repeat!important;
  background-position: 50%!important; }

.l--parallax-50 {
  height: 50vh; }

.border-1 {
  border: 1px solid; }

.border-top-1 {
  border-top: 1px solid; }

.border-right-1 {
  border-right: 1px solid; }

.border-bottom-1 {
  border-bottom: 1px solid; }

.border-left-1,
.border-x-1 {
  border-left: 1px solid; }

.border-x-1 {
  border-right: 1px solid; }

.border-y-1 {
  border-top: 1px solid;
  border-bottom: 1px solid; }

.border-2 {
  border: 2px solid; }

.border-top-2 {
  border-top: 2px solid; }

.border-right-2 {
  border-right: 2px solid; }

.border-bottom-2 {
  border-bottom: 2px solid; }

.border-left-2,
.border-x-2 {
  border-left: 2px solid; }

.border-x-2 {
  border-right: 2px solid; }

.border-y-2 {
  border-top: 2px solid;
  border-bottom: 2px solid; }

.border-3 {
  border: 3px solid; }

.border-top-3 {
  border-top: 3px solid; }

.border-right-3 {
  border-right: 3px solid; }

.border-bottom-3 {
  border-bottom: 3px solid; }

.border-left-3,
.border-x-3 {
  border-left: 3px solid; }

.border-x-3 {
  border-right: 3px solid; }

.border-y-3 {
  border-top: 3px solid;
  border-bottom: 3px solid; }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none!important; } }

.hidden-xl-down {
  display: none!important; }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none!important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none!important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none!important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none!important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none!important; } }

@media (max-width: 767.98px) {
  .hidden-sm-down {
    display: none!important; } }

#loading_screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: $brown-light;
  color: $white;
  text-align: center;
  padding-top: 100px;
  opacity: 1; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $white;
  border-radius: 50%;
  animation: b 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  border-color: $white transparent transparent; }

.lds-ring div:first-child {
  animation-delay: -.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -.15s; }

@keyframes b {
  0% {
    transform: rotate(0deg); }

  to {
    transform: rotate(1turn); } }

h2.divide, .h2.divide {
  position: relative;
  line-height: normal;
  margin: 40px 0 0 0;
  text-align: center;
  @media (min-width: 768px) {
    border-top: 4px solid #EBEBEB;
    height: 40px; } }

.divide > span {
  top: -1.2rem;
  margin: 0 auto;
  background: $white;
  display: inline-block;
  color: $pink;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center;
  @media (min-width: 768px) {
    position: absolute; } }

h2.divide-2 {
  position: relative;
  line-height: normal;
  border-top: 4px solid $white;
  height: 40px;
  margin: 40px 0 0 0;
  text-align: center; }

.divide-2 > span {
  position: absolute;
  top: -1.2rem;
  margin: 0 auto;
  background: #EBEBEB;
  display: inline-block;
  color: $pink;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center; }

.gradient-offerte {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.5+100 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%) /* FF3.6-15 */;
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%) /* Chrome10-25,Safari5.1-6 */;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ) /* IE6-9 */; }

.ico-posizione,.ico-impianti,.ico-meteo,.ico-webcam,.ico-sci,.ico-lingue,.ico-fb,.ico-in,.ico-mail, .ico-tel {
  display: inline-block;
  background: url(../img/sprite.png) no-repeat;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap; }

.ico-posizione {
  background-position: 0 0;
  width: 40px;
  height: 40px; }

.ico-impianti {
  background-position: -40px 0;
  width: 40px;
  height: 40px; }

.ico-meteo {
  background-position: -80px 0;
  width: 40px;
  height: 40px; }

.ico-webcam {
  background-position: -120px 0;
  width: 40px;
  height: 40px; }

.ico-sci {
  background-position: -160px 0;
  width: 35px;
  height: 35px; }

.ico-lingue {
  background-position: -195px 0;
  width: 35px;
  height: 35px; }

.g--ico-preferiti {
  background: url(../img/sprite.png) no-repeat;
  background-position: -230px 0;
  width: 30px;
  white-space: nowrap;
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-block;
  line-height: 40px;
  &.dark {
    color: $brand-success; } }

.ico-servizi-menu {
  background: url(../img/servizi-menu.png) no-repeat;
  width: 76px;
  height: 76px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap; }

.ico-servizi-email {
  background: url(../img/servizi-email.png) no-repeat;
  width: 76px;
  height: 76px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap; }

.ico-servizi-lingua {
  background: url(../img/icon-lingua.png) no-repeat;
  width: 32px;
  height: 32px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap; }

.ico-fb {
  background-position: -265px 0;
  width: 46px;
  height: 46px; }

.ico-in {
  background-position: -311px 0;
  width: 46px;
  height: 46px; }

.ico-mail {
  background-position: -357px 0;
  width: 25px;
  height: 18px; }

.ico-tel {
  background-position: -382px 0;
  width: 18px;
  height: 18px; }

select {
  appearance: none {
    -webkit-appearance: none;
    -moz-appearance: none; } }

.select_arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0px;
  height: 0px;
  border: solid $pink;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

.yellow {
  color: $yellow; }

.scheda {
  background: $white;
  box-shadow: 0px 3px 6px #666666;
  border-radius: 5px; }

@media (max-width: 576px) {
  .btn-scheda {
    width: 100%;
    margin: 2px; } }

body {
 @include media-breakpoint-up(lg) {
   &.g--header-style-white {
     .l--navbar-cnt, .u--menu-holder, {
       background-color: transparent !important;
       .g--link-lastminute, .g--link-offerte, .g--link-hotel, .btn-trasp {
         color: $white;
         background-color: transparent !important;
         border-color: transparent !important; }
       .l--sep-v {
         background-color: $white !important; }
       .l--input-cerca-hotel {
         border-bottom-color: $white !important;
         color: $white !important; } } }
   @include media-breakpoint-down(md) {
     padding-top: $body-padding-top-md; }

   @include media-breakpoint-down(md) {
     padding-top: $body-padding-top-xs; } } }

h1, h2, h3, h4, h5, h6 {
 @include media-breakpoint-down(md) {
   font-size: 1.3rem; } }
h4, .h4 {
 @include media-breakpoint-down(md) {
   font-size: 1rem; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.shadow {
  box-shadow: 0px 3px 6px #00000029 !important; }

.navbar-toggler {
  padding: 0;
  border-radius: 0;
  background: $navbar-toggler-background;
  border: 0;
  @include media-breakpoint-between(xs, md) {
    padding: 0; }
  .icon-bar {
    width: 25px;
    height: 2px;
    margin: 5px auto;
    display: block;
    background: $white;
    transition: all 0.3s ease-out; }
  .top-bar {
    transform: rotate(0) translateX(0) translateY(0); }
  .middle-bar {
    opacity: 1; }
  .bottom-bar {
    transform: rotate(0) translateX(0) translateY(0); }
  &[aria-expanded="true"] {
    background-color: darken($navbar-toggler-background, 5%);
    .top-bar {
      transform: rotate(45deg) translateX(5px) translateY(5px); }
    //transform: rotate(0) translateX(0) translateY(8px)
    .middle-bar {
      opacity: 0;
      transform: rotate(0) translateX(-100%) translateY(0); }
    .bottom-bar {
      transform: rotate(-45deg) translateX(5px) translateY(-5px); } }
 }  //transform: rotate(0) translateX(0) translateY(-8px)

.l--nav-toggler {
 @include media-breakpoint-between(xs, md) {
   width: 14% !important;
   height: $main-logo-height; } }

.l--nav-btn {
 width: 25% !important;
 height: $main-logo-height;
 position: relative;
 overflow: visible !important;
 @include media-breakpoint-between(xs, md) {
   width: 14% !important; }
 } //  padding: 8px 0 14px 20px !important

.l--nav-logo {
 @include media-breakpoint-between(xs, md) {
   width: 63% !important;
   margin-right: 0 !important; }
 .u--landing-page & {
   @include media-breakpoint-between(xs, md) {
     width: 75% !important; } } }

.u--transition {
  transition: all 600ms ease-out; }

#muovi {
  .l--h-img-grid, .js--hotel-cnt {
    height: 100%;
    font-size: .5em;
    text-align: center; } }

.btn-contatta-hotel {
 @include media-breakpoint-down(md) {
   padding: 6px 0; } }
//    font-size: .7em
// .g--ico-preferiti
//     width: 24px
//     height: 24px
//     line-height: 24px
//     font-size: 12px
//     content: ''
.btn {
 overflow: hidden;
 text-overflow: ellipsis;
 cursor: pointer; }

.dropdown-toggle {
 text-decoration: none !important;
 &:after {
   display: inline-block;
   width: auto;
   height: auto;
   line-height: 1;
   margin-left: .3em;
   margin-top: -.25em;
   vertical-align: middle;
   content: "\f107";
   font-family: $font-family-icon;
   border: none !important; } }

.breadcrumb {
  padding: 0;
  background-color: transparent; }

.breadcrumb-item {
  font-size: .8em;
  a {
    color: $breadcrumb-link-color;
    &:hover {
      color: lighten($breadcrumb-link-color, 20%); } } }

.border {
 border: 1px solid $gray-lighter;
 &.border-primary {
   border-color: $brand-primary !important; }
 &.border-secondary {
   border-color: $brand-secondary !important; }
 &.border-success {
   border-color: $brand-success !important; }
 &.border-danger {
   border-color: $brand-danger !important; }
 &.border-warning {
   border-color: $brand-warning !important; }
 &.border-info {
   border-color: $brand-info !important; }
 &.border-light {
   border-color: $gray-lighter !important; }
 &.border-gray {
   border-color: $gray !important; }
 &.border-dark {
   border-color: $black !important; }
 &.border-white {
   border-color: $white !important; } }

.border-w2 {
 border-width: 2px !important; }

.border-w3 {
 border-width: 3px !important; }

.border-w4 {
 border-width: 4px !important; }

.border-radius {
 border-radius: $border-radius; }

.border-radius-sm {
 border-radius: $border-radius-sm; }

.border-radius-lg {
 border-radius: $border-radius-lg; }

.g--link-hotel {
 color: $pink-lighter;
 &:hover {
   color: darken($pink-lighter, 15%); } }


.g--link-offerte {
  color: $brand-offerte;
  &:hover {
    color: darken($brand-offerte, 10%); } }

.g--link-lastminute {
  color: $brand-lastminute;
  &:hover {
    color: darken($brand-lastminute, 10%); } }

.btn-outline-primary {
  color: $brand-primary;
  background-color: transparent;
  background-image: none;
  border-color: $brand-primary; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #544842;
  border-color: #544842; }

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(84, 72, 66, .5); }

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #544842;
  background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #544842;
  border-color: #544842; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(84, 72, 66, .5); }

.btn-outline-secondary {
  color: $green-dark;
  background-color: transparent;
  background-image: none;
  border-color: $green-dark; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: $green-dark;
  border-color: $green-dark; }

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 190, 153, 0.5); }

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: $green-dark;
  background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: $green-dark;
  border-color: $green-dark; }

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 190, 153, 0.5); }

.g--text-cursive {
 font-family: $font-family-script;
 font-weight: normal !important; }

.g--text-colored {
 color: $pink; }

.g--text-dark-colored {
 color: $pink-dark; }

.g--text-white {
 color: $white;
 &:hover {
   color: darken($white, 5%); } }

.g--text-gray {
 color: $gray; }

.g--text-colored-02 {
  color: $brand-offerte; }

.g--text-colored-03 {
  color: $brand-lastminute; }

.g--text-colored-04, .g--text-colored-04:hover {
  color: $green-darker; }

.g--text-colored-05, .g--text-colored-05:hover {
  color: $green-dark; }

.g--text-no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.g--text-nodeco {
  text-decoration: none !important; }

.g--text-shadow {
  text-shadow: 0px 0px 6px $text-shadow; }

.g--text-bordered {
  text-shadow: -1px -1px 0 $text-bordered; }

.g--box-shadow {
  box-shadow: $dropdown-box-shadow; }

.g--text-graysemilight {
  color: $gray-light; }

.g--text-nocursor {
  cursor: inherit !important; }

.g--contenuti {
 h6 {
   color: $pink-dark;
   border-bottom: 1px solid $pink-dark; } }

.l--abstract-list {
 height: $l--h-abstract;
 overflow: hidden; }

.l--pos-r {
 position: relative; }

.l--pos-a {
 position: absolute;
 z-index: 998;
 &.l--pos-no-xs {
   @include media-breakpoint-down(sm) {
     position: relative;
     top: auto;
     left: auto;
     right: auto;
     bottom: auto; } } }

.l--pos-f {
 position: fixed;
 z-index: $zindex-fixed; }

.l--pos-n {
 top: 0; }

.l--pos-w {
 right: 0; }

.l--pos-s {
 bottom: 0; }

.l--pos-e {
 left: 0; }

.l--h-img-grid {
  height: $l--h-img-grid; }

.l--h-img-grid-localita {
  height: 400px; }

.btn-contatta-hotel {
 @include media-breakpoint-up(md) {
   height: $main-logo-height;
   padding: 6px; }
 @extend .btn-trasp;
 //@extend .btn-success
 @extend .btn-block;
 @extend .text-uppercase;
 @extend .rounded-0;
 @extend .g--text-nodeco; }

.dropdown-menu {
  margin-top: 0;
  background-color: $pink-light-trasp;
  border-radius: 5px;
  border: 1px solid $pink; }

/* Megamenu navbar*/
.l--mega-dropdown {
  .dropdown-toggle {
   @include media-breakpoint-down(md) {
     display: block; } }
  .dropdown-menu {
    margin-top: 0;
    .dropdown-header {
     font-size: 1rem;
     @include media-breakpoint-down(md) {
       font-size: 1.6rem; }
     color: darken($pink, 5%);
     font-weight: normal;
     padding: 0.25rem .5rem;
     @extend .g--text-no-overflow; }
    .dropdown-item {
      font-size: 1rem;
      @include media-breakpoint-down(md) {
        font-size: 1.4rem; }
      border-bottom: 1px solid lighten($pink-light, 5%);
      text-decoration: none;
      padding: 0.25rem .5rem; }
    @include media-breakpoint-up(xl) {
      min-width: 700px; }
    @include media-breakpoint-down(md) {
      position: relative !important;
      max-height: calc(100vh - 230px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .row {
        margin: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; } } }
  @include media-breakpoint-down(md) {
    //[class*=g--link-]
    //  color: #fff
    //  font-size: 1.5rem
    //  &:after
    //    transition: all 300ms ease-out
    //  &[aria-expanded="true"]:after
    //    transform: rotate(180deg)
    .dropdown-toggle:after {
      float: right;
      margin-top: .25em;
      margin-right: .5em;
      @include media-breakpoint-down(md) {
        margin-right: 0; } } } }

.g--filters-row {
 margin: -8px -8px 0 -8px; }

.l--filters {
 .dropdown-toggle {
   @include media-breakpoint-down(sm) {
     display: block; } } }

.g--fetching-progress {
 height: auto;
 opacity: .5;
 transition: all 600ms linear; }

.u--landing-page {
 .btn-contatta-hotel {
   @include media-breakpoint-down(xs) {
     padding-left: 45px !important; }
   @include media-breakpoint-up(sm) {
     height: auto;
     padding: $input-btn-padding-y $input-btn-padding-x; } }
 .navbar-collapse {
   display: none !important; }
 .g--image-landing {
   height: 60vh;
   @include media-breakpoint-down(sm) {
     height: auto; } }
 .g--header-landing {
   max-height: 300px;
   @include media-breakpoint-down(sm) {
     max-height: none; } }
 .g--row-preform {
   background-color: $pink-light-trasp;
   @include media-breakpoint-up(sm) {
     position: absolute;
     margin-top: -54px;
     width: 100%; } } }
// .g--filters-row
//   margin: 0
@media (max-width: 991.98px) {
  .navbar-collapse.show .dropdown-menu.show {
    width: 100%; } }

@media (max-width: 576px) {
  .mx-auto-sm {
    margin: auto; } }

[class^=g--stelle-] {
  display: inline-block;
  font-family: $font-family-ico--stella;
  /*font-size: inherit*/
  text-rendering: auto;
  color: $yellow;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  font-weight: 600;
  .form-group & {
    color: $pink; } }

.btn-menu-cat:hover {
  [class^=g--stelle-]:before {
    color: #fff; } }

.g--stelle-1:before {
  content: $ico--stella; }

.g--stelle-2:before {
  content: $ico--stella $ico--stella; }

.g--stelle-3:before {
  content: $ico--stella $ico--stella $ico--stella; }

.g--stelle-3s:before {
  content: $ico--stella $ico--stella $ico--stella + 'S'; }

.g--stelle-4:before {
  content: $ico--stella $ico--stella $ico--stella $ico--stella; }

.g--stelle-5:before {
  content: $ico--stella $ico--stella $ico--stella $ico--stella $ico--stella; }

.g--stelle-6:before {
  font-family: $font-family-sans-serif;
  content: 'Residence';
  font-weight: normal; }

.clearfix:after {
  display: block;
  clear: both;
  content: ""; }

.show-modal {
 display: block !important;
 opacity: 1;
 z-index: 1050 !important; }

#modal-backdrop-hotels {
 z-index: 1040;
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100vh;
 background: rgba(0, 0, 0, .6);
 display: none;
 &.show-back {
   display: block; } }

.l--btn-atf {
  width: 46px;
  height: 40px;
  border: none;
  cursor: pointer;
  background-size: cover; }

.item-added {
  &::after {
    content: "\F00C";
    font-family: "Font Awesome 5 Pro";
    padding-left: 5px; } }


/*Gallery scheda hotel*/
.lSPager {
  li {
    border-radius: 0 !important;
    opacity: .75;
    &.active {
      opacity: 1; } }
  .l--no-pager & {
    display: none; } }

.lSAction > a {
  background-image: none;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1;
  color: $white !important;
  &:after {
    font-family: 'Font Awesome 5 Pro'; }
  &.lSPrev:after {
    content: '\f104'; }
  &.lSNext:after {
    content: '\f105'; } }

.l--gallery-img-cnt {
  height: 300px;
  @include media-breakpoint-up(sm) {
    height: 350px; }
  @include media-breakpoint-up(md) {
    height: 400px; }
  @include media-breakpoint-up(lg) {
    height: 450px; } }

.js--light-slider {
  height: 300px;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    height: 350px; }
  @include media-breakpoint-up(md) {
    height: 400px; }
  @include media-breakpoint-up(lg) {
    height: 450px; } }
.lSGallery {
  @include media-breakpoint-down(md) {
    display: none; } }
.l--gallery-img-cnt {
  height: 300px;
  @include media-breakpoint-up(sm) {
    height: 350px; }
  @include media-breakpoint-up(md) {
    height: 400px; }
  @include media-breakpoint-up(lg) {
    height: 450px; } }

.js--light-slider {
  height: 300px;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    height: 350px; }
  @include media-breakpoint-up(md) {
    height: 400px; }
  @include media-breakpoint-up(lg) {
    height: 450px; } }
.lSGallery {
  @include media-breakpoint-down(md) {
    display: none; } }

.lSSlideOuter .lSPager.lSpg>li {
  background-color: #fff;
  border: 1px solid #b95195;
  border-radius: 10px !important;
  padding: 0 4px !important;
  line-height: 1em;
  margin: 0 1px; }

.lSSlideOuter .lSPager.lSpg>li.active a, .lSSlideOuter .lSPager.lSpg>li:hover a {
  background-color: $pink; }

.lSSlideOuter .lSPager.lSpg>li a {
  background-color: transparent; }

.u--offerte-evento {
 .carousel-indicators {
   bottom: auto;
   top: 0;
   li {
     cursor: pointer;
     width: 10px;
     transition: all 300ms ease-out;
     transform-origin: center center;
     background-color: $brand-offerte;
     opacity: .35;
     &.active {
       width: 30px;
       opacity: 1; } } } }

#select_filters .dropdownList-menu, #filter-categoria .dropdown-menu.show, #filter-eventi .dropdown-menu.show {
 position: absolute;
 max-width: 100%;
 min-width: 290px;
 z-index: 10;
 background: $white;
 max-height: 20rem;
 overflow-y: auto;
 overflow-x: hidden;
 outline: 0;
 display: block;
 @extend .dropdown-menu;
 & > div {
   @extend .dropdown-item; } }

#select_filters_top .dropdownList-menu, #js--collapse-offerte, #js--collapse-categorie {
 position: absolute;
 left: 0;
 right: 0;
 background: $pink;
 padding-left: 20px;
 overflow: auto;
 max-height: calc(100vh - 150px);
 div {
   color: $white;
   margin: 0.8rem 0;
   input[type=checkbox] {
     -webkit-appearance: none;
     box-sizing: border-box;
     padding: 0;
     background: #006dc2;
     border: 1px solid #fff;
     width: 16px;
     height: 16px;
     margin: 0 10px 0 20px;
     &:checked {
       &:after {
         content: 'â';
         display: block;
         font-size: 0.8rem;
         margin: -0.2rem 0.1rem;
         color: $white; } } } } }

#js--collapse-categorie {
 text-align: center;
 a.btn-gridfilter {
   width: 100px; } }
#js--collapse-offerte {
 a {
   text-decoration: none; } }

// #js--collapse-filtro
//   .btn
//     text-align: left
//     padding: 0.2rem 0.6rem
//   .active
//     color: $blue-dark-medium !important
//     background: $white
//     span.text-white
//       color: $blue-dark-medium !important
//filter_offerte #filter-servizio .btn
// padding: 0.2rem 0.4rem

#select_filters_top {
 .filters-holder {
   @extend .row;
   //display: flex
   //justify-content: center
   //.l--pos-r
   //  position: unset
   .form-group {
     margin: 0;
     .l--wrap-calendar {
       background: $blue-dark-medium;
       border: none;
       border-radius: 38px;
       //margin-left: 0.5rem
       margin-left: 0 !important;
       &::after {
         color: $white;
         content: none; }
       input.form-control {
         padding: 0.25rem 0.4rem !important;
         margin-left: 0.3em;
         color: $white;
         font-size: 0.8rem;
         &::placeholder, &:focus {
           color: $white;
           font-size: 0.8rem;
 } }           //text-align: center
       .active {
         background: $blue-dark-medium;
         border-radius: 38px; } } } } }

#filter-servizio .btn, #filter-trattamento .btn, #filter-categoria .btn, #filter-eventi .btn {
  color: #b95195;
  background-color: #ebebeb;
  border-color: #b95195;
  border-radius: 25px;
  font-size: .75rem;
  text-transform: uppercase;
  padding: 0.5rem 2rem;
  margin: .5rem; }

#filter_landing {
 .g--bkg-input {
   color: $gray !important;
   background-color: $input-bg;
   border-radius: 0; }
 #select_filters_top .dropdownList-menu {
   position: absolute;
   width: 100%;
   min-width: 200px;
   z-index: 10;
   background: $white;
   max-height: 20rem;
   overflow-y: auto;
   overflow-x: hidden;
   outline: 0;
   display: block;
   @extend .dropdown-menu;
   & > div {
     @extend .dropdown-item;
     margin: 0; } } }

#js--collapse-filtro .btn {
 font-size: 0.8rem; }

.dropdownList-clear {
 display: inline-block;
 position: absolute;
 right: 5px;
 cursor: pointer;
 top: 35px; }

#select_filters .dropdownList-menu-item {
 position: relative;
 display: block;
 border: none;
 height: auto;
 text-align: left;
 line-height: 1em;
 color: rgba(0, 0, 0, 0.87);
 font-size: 0.8rem;
 text-transform: none;
 font-weight: 400;
 box-shadow: none;
 padding: 0.4rem 0.5rem;
 white-space: normal;
 word-wrap: normal;

 &.isActive {
   color: rgba(0, 0, 0, 0.95);
   background: rgba(0, 0, 0, 0.03); }

 &.isSelected {
   color: rgba(0, 0, 0, 0.95);
   font-weight: 700; } }

.hotel-favourites-dropdown {
 display: none;
 max-height: 480px;
 overflow-y: auto;
 position: absolute;
 top: 55px;
 left: -1px;
 right: -1px;
 z-index: 99;
 border: 1px solid $pink;
 border-radius: 5px;
 background: $pink-light-trasp;
 @extend .p-3;
 //@extend .bg-success
 [class^=g--stelle-] {
   color: $yellow; }
 .btn {
   background: $pink;
   border-color: $pink-dark; }
 .hotel-select-item {
   background-color: $pink-light-trasp !important;
   .g--text-white {
     color: $brown !important; } }
 .col-1 {
   padding: 4px; } }


.g--hotel-search {
 cursor: pointer;
 min-width: 300px;
 max-height: 500px;
 overflow-y: auto;
 padding: .5rem .5rem 0 .5rem;
 @extend .g--hotel-search;
 @extend .l--pos-a;
 @extend .border;
 @extend .g--bkg-light-01;
 .hotel-select-item {
   font-size: .8rem;
   @extend .mb-2;
   img {
     margin-right: 5px; } } }

.g--selected-filters {
 .badge {
   font-size: .8rem; } }

.uâelenco-off {
 .u--select-cat {
   display: none !important; } }

.l--hotel-list {
  margin-bottom: 10px;
  @extend .col-12;
  @extend .col-md-6;
  @extend .col-lg-4; }

.l--contenitore-img {
  //min-height: 230px
  @media(max-width: 480px) {
 }    //height: 150px
  a {
    display: block;
    height: 100%; } }

/* Luoghi interesse schede hotel */
.luoghi_interesse {
  display: none; }

.btn-info {
  color: $pink-dark;
  background-color: #EBEBEB;
  border-color: $pink-dark; }

.btn-info:hover {
  color: $pink-dark;
  background-color: #ccc;
  border-color: $pink-dark; }

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 .2rem rgba(91, 192, 222, .5); }

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #5bc0de;
  border-color: #5bc0de; }

.flex-fill {
  flex: 1 1 auto!important; }

#luoghiInteresse, .luoghi_interesse {
  display: none; }

.Merida-Root [type="checkbox"]:checked + label::before, .Merida-Root [type="checkbox"]:not(:checked) + label::before, .Merida-Root [type=checkbox]:checked + label::before {
  background-color: #D9A7C9 !important; }

.modal-recap-text, .modal-dialog-body p {
  color: $brand-primary !important; }

.Merida-Root .Merida-Modal-Dialog h3, .modal-recap-icon svg, .modal-dialog-body strong, .modal-recap-text strong {
    color: $pink !important; }

.label-form-generico {
  font-size: .8em;
  position: absolute;
  color: #B95195;
  width: 100%;
  padding: 2px 12px; }

.input-form-generico {
  width: 100%;
  background: rgb(217, 167, 201);
  border: 1px solid rgb(180, 78, 148);
  cursor: auto;
  height: 40px;
  padding: 24px 12px 10px;
  font-size: 14px; }

.invio-form-generico {
  color: rgb(255, 255, 255);
  background-color: rgb(52, 199, 126);
  font-size: 1.25em;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  width: auto;
  padding: 0.8em 3em;
  border: 2px solid rgb(44, 167, 106);
  margin: 0px;
  border-radius: 5px; }

.form-generico {}
[type=checkbox] {
  &:checked + label:before, &:not(:checked) + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    background: #fff;
    position: relative;
    border-radius: 0; }

  &:checked + label:before, &:not(:checked) + label:before {
    background-color: #d9a7c9 !important; }

  &:checked + label:before {
    background: #fff !important;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0; }

  &:not(:checked) + label:before {
    width: 24px;
    height: 24px;
    top: 0;
    left: 0; } }

.form-checkbox + label:before {
  border: 1px solid #ccc; }

.form-generico {
  .form-checkbox {
    position: absolute;
    opacity: 0; }

  [type=checkbox] {
    &:checked, &:not(:checked) {
      position: absolute;
      opacity: 0; } }

  .form-checkbox:checked + label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 15px;
    background: #fff;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); } }

.form-generico [type=checkbox] {
  &:checked + label:before, &:not(:checked) + label:before {
    background-color: #d9a7c9 !important; } }

.form-generico .form-checkbox + label span {
  position: relative;
  top: 6px; }

.form-generico {
  .form-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0; }

  [type=checkbox] {
    &:checked + label, &:not(:checked) + label {
      position: relative;
      cursor: pointer;
      padding: 0; } } }

.l--m-h-350 {
  min-height: 350px; }

.wrapper {
  display: block; }

#sidebar-dx {
  width: 100%;
  max-width: 360px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -400px;
  transition: all 0.5s ease-in-out;
  // -webkit-box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2)
  // -moz-box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2)
  // box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.2)
  z-index: 9999;

  &.active {
    right: 0; } }

.overlay-sb {
  display: none;
  position: fixed;

  /* full screen */
  width: 100vw;
  height: 100vh;

  /* middle layer, i.e. appears below the sidebar */
  z-index: 998;
  opacity: 0;

  /* animate the transition */
  transition: all 0.5s ease-in-out;

  &.active {
    display: block;
    opacity: 1;
    top: 0;
    background: rgba(0, 0, 0, 0.1); } }

#dismiss {
  font-size: 2em; }

#pulsantiSidebar {
  margin-right: -81px;
  z-index: 900;
  top: calc(50% - 120px);
  transition: all 0.5s ease-in-out;
  &.active {
    margin-right: 0px; } }

.offerta_testo {
  padding: 0;
  margin: 0;
  p {
    margin-bottom: 0.5rem; } }

.l--h-img-offerte-grid {
  height: 190px; }
